function extractUserId(email: string, asurite?: string) {
  return asurite || email?.split('@').shift() || '';
}

function parsePlanId(planId: string) {
  return `${planId.replaceAll('PLAN#', '')}`;
}

function parseBoolToYesNo(value?: boolean, defaultResponse = 'No') {
  if (value === undefined || value === null) {
    return defaultResponse;
  }

  if (value) {
    return 'Yes';
  }
  return 'No';
}

/**
 * Convert 5 digits term to 4 digits format
 * @example const term = to4digitsTerm("20217")
 * console.log(term); // 2217
 * @param sTerm
 */
function toTerm4Digits(sTerm: string) {
  if (sTerm.length === 5) {
    return sTerm.slice(0, 1) + sTerm.slice(2);
  }
  return sTerm;
}

function isMaxCreditWarning(
  reason: API.PlanData.PlanValidationError['reason'],
) {
  return (
    reason === 'TERM_CREDIT_HOURS_EXCEED' ||
    reason === 'SESSION_CREDIT_HOURS_EXCEED'
  );
}

export {
  extractUserId,
  isMaxCreditWarning,
  parseBoolToYesNo,
  parsePlanId,
  toTerm4Digits,
};
