import { RoleName } from './app-constants';

const appRouterUrl = {
  // ============ App urls ============
  HOME: '/',
  NOT_AUTHORIZED: '/401-user-not-authorized',
  NOT_FOUND: '/404-resource-not-found',
  LOG_OUT: '/logout',
  // ============ PLan urls ============
  PLAN_DETAIL: '/plans',
  PLAN_ACTIVE: '/plans/active',
  PLAN_DETAIL_BY_PLAN_ID: '/plans/:planId',
  PLAN_VIEW_BY_EMPLID_PLAN_ID: '/plans/view/:searchEmplId/:planId',
  // ============ Student urls ============
  STUDENT_PROFILE: '/student/profile',
  STUDENT_DASHBOARD: '/student/dashboard',
  // ============ ADMIN urls ============
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_PROFILE_VIEW: '/admin/dashboard/student',
  ADMIN_PROFILE_VIEW_BY_ID: '/admin/dashboard/student/:searchEmplId',
} as const;

export type RouterUrlValue = (typeof appRouterUrl)[keyof typeof appRouterUrl] &
  string;

const parsePlanDetailViewRoute = (
  searchEmplId: string,
  planId: string = 'active',
) => {
  let url = appRouterUrl.PLAN_VIEW_BY_EMPLID_PLAN_ID.replace(
    ':searchEmplId',
    searchEmplId,
  );
  url = url.replace(':planId', planId);

  return url;
};

const startPageByRole: Record<string, string> = {
  [RoleName.PLATFORM]: appRouterUrl.ADMIN_DASHBOARD,
  [RoleName.ADMIN]: appRouterUrl.ADMIN_DASHBOARD,
  [RoleName.ADVISOR]: appRouterUrl.ADMIN_DASHBOARD,
  [RoleName.COACH]: appRouterUrl.ADMIN_DASHBOARD,
  [RoleName.STUDENT]: appRouterUrl.STUDENT_DASHBOARD,
  [RoleName.NONE]: appRouterUrl.NOT_FOUND,
};

export { appRouterUrl, startPageByRole, parsePlanDetailViewRoute };
