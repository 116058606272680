import {
  Children,
  cloneElement,
  ElementRef,
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { generateId } from 'core/utils/generate-id';
import { isElementInViewport } from 'core/utils/html-utils';
import styles from './index.module.scss';

type ReactKeyboardEvent = React.KeyboardEvent<HTMLElement>;
type OpenMenuEvent = {
  target: EventTarget;
  currentTarget: EventTarget & HTMLElement;
  stopPropagation: () => void;
  preventDefault: () => void;
};

//#region "MenuItem"
type MenuItemProps = {
  children: React.ReactNode;
  /**
   * When `false` (default) the MenuList allow navigation between `<li />` items
   * Whe `true` the MenuList expects that the `tabindex` is set externally.
   * @example
   * <MenuItem><a>My link</a></MenuItem>
   * @example
   * <MenuItem><button>My link</button></MenuItem>
   * @example
   * <MenuItem><div tabIndex={0}>My link</div></MenuItem>
   */
  customTabIndex?: boolean;
  disabled?: boolean;
} & React.FormHTMLAttributes<HTMLLIElement>;

const findSibling = (
  target: HTMLElement,
  sideSibling: 'previousSibling' | 'nextSibling',
) => {
  let sibling = target;
  let isDisabled;

  do {
    sibling = sibling[sideSibling] as HTMLElement;
    isDisabled = sibling?.getAttribute('data-disabled');

    if (isDisabled === 'false' || !isDisabled) {
      break;
    }
  } while (sibling);

  return sibling;
};

const MenuItem = ({
  children,
  customTabIndex = false,
  disabled = false,
  onClick,
  ...props
}: MenuItemProps) => {
  const selectMenuItem = (e: ReactKeyboardEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const target = e.currentTarget as HTMLElement;
    let sibling = null;

    if (e.key === 'ArrowUp') {
      sibling = findSibling(target, 'previousSibling');
    } else if (e.key === 'ArrowDown') {
      sibling = findSibling(target, 'nextSibling');
    }

    if (sibling) {
      const element = sibling.hasAttribute('tabindex')
        ? sibling
        : sibling.querySelector<HTMLElement>('a,[tabindex="0"]');

      if (element?.focus) {
        element.focus();
      }
    }
  };

  return (
    <li
      {...props}
      role="menuitem"
      tabIndex={customTabIndex || disabled ? undefined : 0}
      data-disabled={disabled}
      onKeyDown={
        disabled
          ? undefined
          : (e) => {
              if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
                selectMenuItem(e);
              } else if (e.key === 'Enter') {
                e.currentTarget.click();
              }
            }
      }
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </li>
  );
};
//#endregion

//#region "MenuList"

type MenuChild = ReactElement;
type MenuAnchorProps = React.HTMLAttributes<HTMLElement> & {
  ref: React.RefObject<any>;
};
type Props = {
  id?: string;
  menuPortalTarget?: HTMLElement;
  focusNextTargetOnClose?: boolean;
  className?: string;
  children: MenuChild | MenuChild[];
  disabled?: boolean;
  menuIsOpen?: boolean;
  /**
   * Pause the action of closing for the specified value
   */
  closingDelayTimeout?: number;
  /**
   * set which direction the MenuList shows up
   */
  direction?: 'right' | 'left';
  /**
   * Any valid JSX element to which attach the events to show the MenuList
   *  @example
   * (props) =>(<Button {...props}>Click to open the menu</Button>)
   */
  anchorComponent: (props: MenuAnchorProps) => ReactElement;
  onClick?: React.MouseEventHandler<HTMLElement>;
};
type MenuContainerProps = {
  id: string;
  anchorEl: HTMLElement | null;
  mounted: boolean;
  requestUnmount: boolean;
  selectFirstItem: boolean;
  onClose: () => void;
  onLeaveLastItem: () => void;
} & Pick<
  Props,
  | 'menuPortalTarget'
  | 'focusNextTargetOnClose'
  | 'className'
  | 'direction'
  | 'children'
  | 'onClick'
>;

let menuEnterTimer: NodeJS.Timer;
let menuCloseTimer: NodeJS.Timer;
let visibilityTimer: NodeJS.Timer;

const showMenu = (
  anchorEl: HTMLElement,
  menuList: HTMLUListElement,
  direction?: string,
  menuPortalTarget?: HTMLElement,
) => {
  if (!anchorEl) return;

  if (menuPortalTarget) {
    const rect = (anchorEl.parentElement || anchorEl).getBoundingClientRect();

    menuList.setAttribute('style', '');

    if (direction === 'right') {
      const posRight = window.innerWidth - rect.left + 'px';
      menuList.style.right = posRight;
      menuList.style.top = rect.y + 'px';
    } else {
      menuList.style.left = rect.x + 'px';
      menuList.style.right = 'unset';
      menuList.style.top = rect.y + rect.height + 'px';
    }

    visibilityTimer = setTimeout(() => {
      const isFullyVisible = isElementInViewport(menuList);
      if (!isFullyVisible) {
        menuList.style.transform = 'translateY(-100%)';
        menuList.style.transition = 'translateY 0.3s ease';
      }
    }, 500);
  } else {
    if (direction === 'right') {
      menuList.style.left = 'unset';
      menuList.style.right = '0px';
    } else {
      menuList.style.left = '0px';
      menuList.style.right = 'unset';
    }
  }

  menuList.style.visibility = '';
  menuList.style.display = '';
  window.dispatchEvent(
    new CustomEvent('menu-list-show', {
      detail: { menuId: menuList?.id || 'none' },
    }),
  );
};

const hideMenu = (menuList: HTMLUListElement, closingDelayTimeout = 800) => {
  clearTimeout(menuEnterTimer);
  clearTimeout(visibilityTimer);

  if (menuList) {
    menuList.setAttribute('data-animate-hidden', 'true');
    menuEnterTimer = setTimeout(() => {
      menuList.setAttribute('data-animate-hidden', 'false');
    }, closingDelayTimeout);
  }
};

const MenuContainer = ({
  id,
  className,
  mounted,
  requestUnmount,
  selectFirstItem,
  direction,
  anchorEl,
  focusNextTargetOnClose,
  menuPortalTarget,
  children,
  onClick,
  onClose,
  onLeaveLastItem,
}: MenuContainerProps) => {
  const menuListRef = useRef<ElementRef<'ul'>>(null);

  if (requestUnmount && menuListRef.current) {
    hideMenu(menuListRef.current);
  }

  const listItems = Children.map(children, (child, index) =>
    cloneElement(child, {
      'data-index': index,
    }),
  );

  const leaveAfterLastMenuItem = (e: ReactKeyboardEvent) => {
    const target = e.target as HTMLElement;
    const selectableItems = menuListRef.current!.querySelectorAll(
      "[data-disabled='false']",
    );
    const lastChild = selectableItems[selectableItems.length - 1];
    const isLastChild = lastChild!.contains(target);

    if (e.key === 'Tab' && isLastChild) {
      onLeaveLastItem();
      if (focusNextTargetOnClose) {
        window.queueMicrotask(() => anchorEl?.focus());
      }
    }
  };

  useEffect(() => {
    if (!menuListRef.current || !anchorEl) return;

    if (mounted) {
      showMenu(anchorEl, menuListRef.current, direction, menuPortalTarget);
    }
  }, [anchorEl, direction, mounted, menuPortalTarget]);

  useEffect(() => {
    if (!menuListRef.current) return;

    if (selectFirstItem) {
      const target =
        menuListRef.current?.querySelector<HTMLElement>('a,[tabindex="0"]');
      target?.focus();
    }
  }, [selectFirstItem]);

  useEffect(() => {
    if (!menuListRef.current) return;

    const closeMenu = () => {
      onClose();
    };
    const events = ['click', 'resize'];
    events.forEach((name) => window.addEventListener(name, closeMenu));
    window.addEventListener('scroll', closeMenu, {
      capture: true,
    });

    return () => {
      clearTimeout(menuEnterTimer);
      events.forEach((name) => window.removeEventListener(name, closeMenu));
      window.removeEventListener('scroll', closeMenu);
    };
  }, [onClose]);

  return mounted ? (
    <ul
      ref={menuListRef}
      className={`${className} ${styles['list']} absolute`}
      id={id}
      role={mounted ? 'menu' : 'presentation'}
      data-is-open={mounted && requestUnmount === false}
      tabIndex={-1}
      data-direction={direction}
      data-items-count={listItems.length}
      onClick={onClick}
      onKeyDown={leaveAfterLastMenuItem}
      onMouseEnter={() => clearTimeout(menuCloseTimer)}
      onAnimationEnd={(e) => {
        if (e.animationName.includes('hide-menu')) {
          onClose();
        }
      }}
    >
      {listItems}
    </ul>
  ) : null;
};

const MenuList: FC<Props> = ({
  anchorComponent,
  menuPortalTarget,
  focusNextTargetOnClose,
  children,
  id: propId,
  className,
  disabled = false,
  menuIsOpen = false,
  closingDelayTimeout = 300,
  direction = 'left',
  onClick,
}) => {
  const [isMenuMounted, setMenuMounted] = useState(menuIsOpen);
  const [requestMenuUnmount, setRequestMenuUnmount] = useState(false);
  const [isFirstItemSelected, setFirstItemSelected] = useState(false);
  const [isPointerEventActive, setPointerEventActive] = useState(true);

  const anchorRef = useRef<HTMLElement>(null);
  const refKey = useRef(propId || generateId('menu-list-'));
  const id = refKey.current;

  const unmountMenu = () => {
    setRequestMenuUnmount(true);
  };

  const mountMenu = (e: OpenMenuEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    e.currentTarget.focus({
      preventScroll: true,
    });

    setMenuMounted(true);
    setRequestMenuUnmount(false);
  };

  const toggleMenu = (e: OpenMenuEvent) => {
    isMenuMounted ? unmountMenu() : mountMenu(e);
  };

  useEffect(() => {
    const closeOtherMenu = (e: Event) => {
      const event = e as CustomEvent;
      if (isMenuMounted && event.detail?.menuId !== id) {
        unmountMenu();
      }
    };
    window.addEventListener('menu-list-show', closeOtherMenu);

    return () => {
      window.removeEventListener('menu-list-show', closeOtherMenu);
    };
  }, [id, isMenuMounted]);

  const Menu = (
    <MenuContainer
      id={id}
      className={className}
      direction={direction}
      mounted={isMenuMounted}
      requestUnmount={requestMenuUnmount}
      anchorEl={anchorRef.current}
      selectFirstItem={isFirstItemSelected}
      menuPortalTarget={menuPortalTarget}
      focusNextTargetOnClose={focusNextTargetOnClose}
      onClick={onClick}
      onLeaveLastItem={unmountMenu}
      onClose={() => {
        setMenuMounted(false);
        setRequestMenuUnmount(false);
        setFirstItemSelected(false);
      }}
    >
      {children}
    </MenuContainer>
  );
  return (
    <div
      className="relative w-fit"
      data-menu-list
      onMouseLeave={() =>
        (menuCloseTimer = setTimeout(unmountMenu, closingDelayTimeout))
      }
    >
      <div className={styles['anchor-element']}>
        {anchorComponent(
          disabled
            ? ({} as MenuAnchorProps)
            : {
                ref: anchorRef,
                tabIndex: 0,
                'aria-controls': id,
                'aria-expanded': isMenuMounted,
                'aria-haspopup': 'true',
                onKeyDown: (e) => {
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    setMenuMounted(false);
                  } else if (e.key === 'Enter') {
                    e.preventDefault();
                    toggleMenu(e);
                  } else if (e.key === 'Tab') {
                    e.stopPropagation();
                    setMenuMounted(false);
                  } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setFirstItemSelected(true);
                  }
                },
                onClick: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  isPointerEventActive && toggleMenu(e);
                },
                onMouseEnter: (e) => {
                  setPointerEventActive(false);
                  mountMenu(e);
                  menuEnterTimer = setTimeout(() => {
                    setPointerEventActive(true);
                  }, 300);
                },
              },
        )}
      </div>
      {menuPortalTarget
        ? createPortal(Menu, menuPortalTarget, refKey.current)
        : Menu}
    </div>
  );
};
//#endregion

export { MenuList, MenuItem };
