import { createSelector } from '@reduxjs/toolkit';

import { appRouterUrl, startPageByRole } from 'core/constants/router-url';
import { RootState, useAppSelector } from '..';

const selectDashboardUrl = createSelector(
  (state: RootState) => state.userProfile,
  (userProfile) => {
    const role = userProfile.viewAs.currentRole || userProfile.currentRole;

    const dashboard = role
      ? startPageByRole[role] || appRouterUrl.NOT_FOUND
      : appRouterUrl.NOT_FOUND;

    return dashboard;
  },
);

const useDashboardUrlSelector = () => useAppSelector(selectDashboardUrl);

export { selectDashboardUrl, useDashboardUrlSelector };
